<template>
    <div style="max-width: 400px; margin: 10vh auto;" class="text-center">
        <br>
        <img src="@/assets/303-image-1.png" class="img-fluid"/>
        <br>
        <br>
        <h1>Accesso negato</h1>
        <br>
        <router-link class="btn btn-secondary" to="/">Torna alla home</router-link>
    </div>
</template>

<script>
export default {
    name: "Denied"
}
</script>

<style scoped>

</style>
